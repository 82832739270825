body::-webkit-scrollbar {
    display: none;
  }
  
  body {
    overflow: hidden;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  

  .UserLandingPage {
    background-image: url('./images/recipeguru-portrait.png'); 
    background-size: 100% 100%;
    
    background-attachment: fixed;
    border: 2px solid;
    resize: none;
    overflow: hidden;
   }
  
   /* Landscape */
  @media screen and (orientation: landscape) {
    .UserLandingPage {
      background-image: url('./images/recipeguru-landscape.png');
      background-size: cover; 
    }
  }

  @media (max-width: 768px) {
    .UserLandingPage {
        background-image: none;
        background-color: #aaaaaa;
    }
  }