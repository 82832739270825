body::-webkit-scrollbar {
    display: none;
}

body {
    overflow: hidden;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}


.CookiePolicy {
    background-image: url('./images/recipeguru-portrait.png');
    background-size: 100% 100%;

    background-attachment: fixed;
    border: 2px solid;
    resize: none;
    overflow: hidden;
}

/* Landscape */
@media screen and (orientation: landscape) {
    .CookiePolicy {
        background-image: url('./images/recipeguru-landscape.png');
        background-size: cover;
    }
}

@media (max-width: 768px) {
    .CookiePolicy {
        background-image: none;
        background-color: #000000;

    }

    .CookieMain {
        margin-top: 2px;
        padding-left: 5px;
        width: 100%;
        height:100%;

        background-color: #000000;
        color: #dddddd;
        

        

    }
}