@media (min-width: 768px) {
  f.navbar {
    position: fixed;
    top: 0;
    right: 75px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0px;
    width: 100%;
    background-color: transparent;
    z-index: 1;
  }
  .nav-title {
    visibility: hidden;
  }

  .header-banner{
    visibility: hidden;
  }

  .navbar-nav {
    list-style: none;
    display: flex;
    gap: 0px;
    margin: 0px;
    margin-left: -80px;
    border-bottom-style: solid;
    border-width: 1px;
    border-color: #000000;
    background-color: #ffffff;
    height: 30px;
    width: calc(100vw + 58px);
    position: fixed;
    top: 0;
    z-index: 1000; 
  }

  .nav-link {
    color: #000077;
    text-decoration: none;
    display: flex; /* Make .nav-link a flex container */
    align-items: center; /* Center content vertically */
    height: 100%; /* Ensure .nav-link takes full height of its parent */
    padding-right: 20px;
    padding-left: 20px;
    font-size: 16px;
    /*font-weight: bold;*/
    font-family:Arial, Helvetica, sans-serif;
    border-right-style: solid;
    border-width: 1px;
    border-color: #000077;
  }

  .nav-link:hover {
    color: rgb(229, 229, 240);
    background-color: #000077;
  }

  .hamburger-menu {
    display: none;
  }
}

/*

MOBILE LAYOUT BELOW

*/
@media (max-width: 768px) {


  .navbar-nav {
    list-style: none;
    margin: -20px;
    
  }
  .navbar-nav .nav-item {
    margin-bottom: 14px; /* Adjust the value as needed */
    font-weight: bold;
  }

  .nav-item2 {
    margin-bottom: 30px; /* Adjust the value as needed */
     /* Adjust the value as needed */
    font-weight: bold;
  }

  .nav-title {
    margin-bottom: 30px; /* Adjust the value as needed */
    font-size: 20px;
    font-weight: bold;
  }
  

  .nav-link {
    color: #dddddd;
    text-decoration: none;
    
  }

  .nav-link:hover {
    color: rgb(238, 24, 24);
  }

  .w50 { --width: 100%}
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  .header-menu{
    flex-grow: 1;
  }
  .header-banner {
    position:absolute;
    left: calc(100% / 6.5);
    align-items: left;
    flex-grow: 1;
    height: 40px;
    font-size: 34px;
    background-color: #333333;
    color: #dddddd;
  }

  .header {
    display: flex;
    position: relative;
    width: 180%;
    background-color: #333333;
    --bar-width: 40px;
    --bar-height: 4px;
    --hamburger-gap: 6px;
    --foreground: #333;
    --background: #dddddd;
    --hamburger-margin: 8px;
    --animation-timing: 200ms ease-in-out;
    --hamburger-height: calc(var(--bar-height) * 3 + var(--hamburger-gap) * 2);
   height: 40px;
  }

  .hamburger-menu {
    --x-width: calc(var(--hamburger-height) * 1.41421356237);

    display: flex;
    flex-direction: column;
    gap: var(--hamburger-gap);
    width: max-content;
    position: absolute;
    top: var(--hamburger-margin);
    left: var(--hamburger-margin);
    z-index: 2;
    cursor: pointer;
  }

  .hamburger-menu:has(input:checked) {
    --foreground: #dddddd;
    --background: #333;
  }

  .hamburger-menu {
    --foreground: #dddddd;
    --background: #dddddd;
  }

  .hamburger-menu:has(input:focus-visible)::before,
  .hamburger-menu:has(input:focus-visible)::after,
  .hamburger-menu input:focus-visible {
    border: 1px solid var(--background);
    box-shadow: 0 0 0 1px var(--foreground);
  }

  .hamburger-menu::before,
  .hamburger-menu::after,
  .hamburger-menu input {
    content: "";
    width: var(--bar-width);
    height: var(--bar-height);
    background-color: var(--foreground);
    border-radius: 9999px;
    transform-origin: left center;
    transition: opacity var(--animation-timing), width var(--animation-timing),
      rotate var(--animation-timing), translate var(--animation-timing),
      background-color var(--animation-timing);
  }

  .hamburger-menu input {
    appearance: none;
    padding: 0;
    margin: 0;
    outline: none;
    pointer-events: none;
  }

  .hamburger-menu:has(input:checked)::before {
    rotate: 45deg;
    width: var(--x-width);
    translate: 0 calc(var(--bar-height) / -2);
  }

  .hamburger-menu:has(input:checked)::after {
    rotate: -45deg;
    width: var(--x-width);
    translate: 0 calc(var(--bar-height) / 2);
  }

  
  .hamburger-menu input:checked {
    opacity: 0;
    width: 0;
  }

  .sidebar {
    position: relative;
    transition: translate var(--animation-timing);
    translate: -100%;
    padding-top: calc(var(--hamburger-height) + var(--hamburger-margin) + 1rem);
    background-color: var(--foreground);
    color: var(--background);
    max-width: 10rem;
    min-height: 100vh;
    z-index: 1;
    top:40px;
  }

  .hamburger-menu:has(input:checked)+.sidebar {
    translate: 0;
  }
}