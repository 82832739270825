body::-webkit-scrollbar {
  display: none;
}

body {
  overflow: hidden;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.Homepage {
  background-image: url('./images/recipeguru-portrait.png'); 
  background-size: 100% 100%;
  
  background-attachment: fixed;
  border: 2px solid;
  resize: none;
  overflow: hidden;
  /*background-position: center -160px; /* Shifts the image upwards */
 }

 /* Landscape */
@media screen and (orientation: landscape) {
  .Homepage {
    background-image: url('./images/recipeguru-landscape.png');
    background-size: cover;

  }
}