body::-webkit-scrollbar {
    display: none;
}

body {
    overflow: hidden;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}


.VoiceChat {
    background-image: url('./images/recipeguru-portrait.png');
    background-size: 100% 100%;

    background-attachment: fixed;
    border: 2px solid;
    resize: none;
    overflow: hidden;
}

/* Landscape */
@media screen and (orientation: landscape) {
    .VoiceChat {
        background-image: url('./images/recipeguru-landscape.png');
        background-size: cover;
    }
}

@media (max-width: 768px) {
    .VoiceChat {
        background-image: none;
        background-color: #333333;
    }

    .SoundContainer {
        width: 100%;
        height: 10%;
        background-color: #333333;
        /* Adjust the color as needed */
        margin: auto;
        /* Center the circle within its parent */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ListeningCircle {
        width: 300px;
        height: 300px;
        border-radius: 50%;
        background-color: #000000;
        /* Adjust the color as needed */
        margin: auto;
        /* Center the circle within its parent */
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .AudioRender{
        justify-content: center;
        background-color: #000000;
    }
    

    .Processing {
        width: 100%;
        height: 20%;
        background-color: #333333;
        /* Adjust the color as needed */
        margin: auto;
        padding-top: 30px;
        /* Center the circle within its parent */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .StartTalking {
        color: #ffffff;
        font-size: 24px; /* Adjust the size as needed */
        font-weight: bold; /* Makes the text bold */
        margin-top: 20px;
    }
    .hiddenText {
        visibility: hidden;
    }
    .circle {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #dddddd;
        display: inline-block;
        margin: 5px;
    }

    .circle.bounce {
        animation-name: bounce;
        animation-duration: 0.85s;
        animation-iteration-count: infinite;
        animation-timing-function: ease;
        animation-delay: var(--animation-delay, 0s);
        /* Use CSS variables for delay */
    }

    .circle:nth-child(1) {
        animation-delay: 0.1s;
    }

    .circle:nth-child(2) {
        animation-delay: 0.2s;
    }

    .circle:nth-child(3) {
        animation-delay: 0.3s;
    }

    .circle:nth-child(4) {
        animation-delay: 0.4s;
    }

    @keyframes bounce {

        0%,
        100% {
            transform: translateY(0);
        }

        50% {
            transform: translateY(-24px);
        }
    }
}