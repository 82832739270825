

.PrivacyPolicy {
    background-image: url('./images/recipeguru-portrait.png');
    background-size: 100% 100%;
    overflow-y: auto; /* Vertical scrollbar if needed */
    overflow-x: hidden;
    background-attachment: fixed;
    border: 2px solid;  
}
.PrivacyMain {
    margin-top: 200px;
    margin-left: 100px;
    margin-right: 200px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 6px;
    width: calc(100vw - 200px);
    font-family:Arial, Helvetica, sans-serif;
    font-size: 16px;
    border-radius: 2cap;
    background-color: #333333;
    color: #dddddd;
}
.PrivacyMain A {
    color: #ffffff;
    text-decoration: underline;
    font-style: bold;
    font-size: medium;
    
  }

  .PrivacyMain A:hover {
    color: rgb(238, 24, 24);
  }


/* Landscape */
@media screen and (orientation: landscape) {
    .PrivacyPolicy {
        background-image: url('./images/recipeguru-landscape.png');
        background-size: cover;
    }
}

@media (max-width: 768px) {
    .PrivacyPolicy {
        background-image: none;
        background-color: #000000;
        -webkit-overflow-scrolling: touch; /* Smooth scrolling for touch devices */
        
        /* Hide scrollbar for WebKit browsers */
        scrollbar-width: none; /* For Firefox */
        -ms-overflow-style: none; /* For Internet Explorer and Edge */
    }

    .PrivacyMain {
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 2px;
        padding-left: 10px;
        padding-right: 10px;
        width: 100%;
        height:100%;
        background-color: #000000;
        color: #dddddd;
    }
}