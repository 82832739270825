.footer {
  position: fixed;
  display: flex;
  bottom: 00;
  width: 100%;
  height: 64px;
}


@media (max-width: 768px) {
  .footer {
    background-color: #333333;

  }

  .column {
    flex: 1;
    text-align: center;
    align-items: center;

  }
  .disabledColumn {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .column.selected {
    background-color: #dddddd;
    /* Example background color for selected column */
  }

  /* Default SVG color */
  .column svg {
    filter: invert(100%) sepia(0%) saturate(6293%) hue-rotate(139deg) brightness(103%) contrast(73%);
    /* https://codepen.io/sosuke/pen/Pjoqqp this has a filter  covertor */

  }

  /* Active SVG color when selected */
  .column.selected .active-svg {
    /* red filter: invert(8%) sepia(91%) saturate(7361%) hue-rotate(4deg) brightness(98%) contrast(110%); */
    filter: invert(20%) sepia(0%) saturate(0%) hue-rotate(254deg) brightness(99%) contrast(100%);
  }

}