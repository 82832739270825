/*--------------------
Body
--------------------*/
*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (max-width: 768px) {
    
    .SearchBar {
       top:-160px;
       height: 50px;
       position: relative;

    }  ;
    

}

body {
  height: 100vh;
  margin: 0;  
  background: #DEDEDE;
  color: #5a6674;
  font-size: 13px;
  font-family: 'Roboto', sans-serif;
  overflow: hidden;
}

/*--------------------
App
--------------------*/
.search-form {
  position: relative;
  top: 200px;
  left: 50%;
  width: 400px;
  height: 40px;
  border-radius: 40px;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  transform: translate(-50%, -50%);
  background: #fff;
  transition: all 0.3s ease;

}

.search-form.focus {
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.15);
}

.search-input {
  position: absolute;
  top: 10px;
  left: 38px;
  font-size: 14px;
  background: none;
  color: #5a6674;
  width: 195px;
  height: 20px;
  border: none;
  appearance: none;
  outline: none;
}

.search-input::-webkit-search-cancel-button {
  appearance: none;
}

.search-button {
  position: absolute;
  top: 10px;
  left: 15px;
  height: 20px;
  width: 20px;
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  outline: none!important;
  cursor: pointer;
}

.search-button svg {
  width: 20px;
  height: 20px;
  fill: #5a6674;
}

.search-option {
  position: absolute;
  text-align: right;
  top: 10px;
  right: 15px;
}

.search-option div {
  position: relative;
  display: inline-block;
  margin: 0 1px;
  cursor: pointer;
}

.search-option div input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.01;
  cursor: pointer;
}

.search-option div span {
  position: absolute;
  display: block;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  background: #929AA3;
  color: #fff;
  font-size: 9px;
  letter-spacing: 1px;
  line-height: 1;
  text-transform: uppercase;
  padding: 4px 7px;
  border-radius: 12px;
  top: -18px;
  transition: all .2s ease-in-out;
}

.search-option div span::after {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 50%;
  transform: translateX(-50%);
  border-top: 4px solid #929AA3;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  transition: all .2s ease-in-out;
}

.search-option div:hover span {
  opacity: 1;
  top: -21px;
}

.search-option div label {
  display: block;
  cursor: pointer;
}

.search-option div svg {
  height: 20px;
  width: 20px;
  fill: #5a6674;
  opacity: 0.6;
  transition: all .2s ease-in-out;
  pointer-events: none;
}

.search-option div:hover svg {
  opacity: 1;
}

.search-option div input:checked + label svg {
  fill: #e24040;
  opacity: .9;
}

.search-option div input:checked + label span {
  background: #e24040;
}

.search-option div input:checked + label span::after {
  border-top-color: #e24040;
}
