body::-webkit-scrollbar {
  display: none;
}

body {
  overflow: hidden;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.Register {
  background-size: 100% 100%;
  background-attachment: fixed;
  border: 2px solid;
  resize: none;
  overflow: hidden;
 }