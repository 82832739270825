body::-webkit-scrollbar {
    display: none;
  }
  
  body {
    overflow: hidden;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  
  .RecipeBook {
    background-size: cover;
    background-attachment: fixed;
    border: 2px solid;
    resize: none;
    overflow: hidden;
   }
  
   .panel {
    
    position: absolute;
    top: 100px;
    left: 0;
    width: 300px;
    height: 80vh;
    background: #f0f0f0;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
    word-wrap: break-word;
    overflow-wrap: break-word;
    overflow: auto;
    white-space: auto;
  }
  
  
  
  .panel.open {
    transform: translateX(0);
  }
  
  .status-icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    left: 10px;
  }
  
  .status-icon.connected {
    background: green;
    animation: pulse 8s infinite;
  }
  
  .status-icon.disconnected {
    background: red;
  }
  
  .forget-button {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
  }
  
  @keyframes pulse {
    40% {
      opacity: 2;
    }
    70% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }