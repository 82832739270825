body::-webkit-scrollbar {
    display: none;
}

body {
    overflow: hidden;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}


.Subscribe {
    background-image: url('./images/recipeguru-portrait.png');
    background-size: 100% 100%;

    background-attachment: fixed;
    border: 2px solid;
    resize: none;
    overflow: hidden;
}

/* Landscape */
@media screen and (orientation: landscape) {
    .Subscribe {
        background-image: url('./images/recipeguru-landscape.png');
        background-size: cover;
    }
}

@media (max-width: 768px) {
    .Subscribe {
        background-image: none;
        background-color: #000000;

    }


    .SubscribeHeader {
        margin-top: 2px;
        padding-left: 5px;
        width: 100%;

        background-color: #333333;
        color: #ffffff;
        /* Adjust the color as needed */

        /* Center the circle within its parent */
        display: flex;
        flex-direction: column;

        justify-content: center;
        align-items: left;
    }

    .SubscribeBody {
        width: 100%;
        height: 85%;
        background-color: #000000;
        /* Adjust the color as needed */
        margin: 0;
        padding-top: 0px;
        /* Center the circle within its parent */
        display: flex;
        justify-content: center;
        align-items: top;
    }

    .Youtube {
        height: calc((100vw/16)*8)
    }

}